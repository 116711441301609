@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

#section1 {
  background: url(../src/utils/Images/Post\ 5.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; 
  height: 50vh; /* 100% of viewport height */
  margin: 0 auto; 
  position: relative; 
}

#section-location {
  background: url(../src/utils/Images/Auditorium/SanctuaryAuditorium.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center; 
  height: 80vh; /* 100% of viewport height */
  margin: 0 auto; 
  position: relative; 
}

.card-cover {
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.card-hover:hover {
  background-color: #FFA500;
}

.montserrat-thin {
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.montserrat-extralight {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.montserrat-light {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-style: normal;
}

.montserrat-regular {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.montserrat-medium {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.montserrat-semibold {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.montserrat-bold {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.montserrat-extrabold {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.montserrat-black {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.montserrat-thin-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.montserrat-extralight-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.montserrat-light-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.montserrat-regular-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.montserrat-medium-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.montserrat-semibold-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.montserrat-bold-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.montserrat-extrabold-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.montserrat-black-italic {
  font-family: "Montserrat", sans-serif;
  font-weight: 900;
  font-style: italic;
}

.poppins-thin {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: normal;
}

.poppins-extralight {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: normal;
}

.poppins-light {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: normal;
}

p, .poppins-regular {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: normal;
}

.poppins-medium {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: normal;
}

.poppins-semibold {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: normal;
}

.poppins-bold {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: normal;
}

.poppins-extrabold {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: normal;
}

.poppins-black {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: normal;
}

.poppins-thin-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 100;
  font-style: italic;
}

.poppins-extralight-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 200;
  font-style: italic;
}

.poppins-light-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 300;
  font-style: italic;
}

.poppins-regular-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 400;
  font-style: italic;
}

.poppins-medium-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  font-style: italic;
}

.poppins-semibold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 600;
  font-style: italic;
}

.poppins-bold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 700;
  font-style: italic;
}

.poppins-extrabold-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 800;
  font-style: italic;
}

.poppins-black-italic {
  font-family: "Poppins", sans-serif;
  font-weight: 900;
  font-style: italic;
}
